import { template as template_968930b68b914ccd81e0b12a7089faf0 } from "@ember/template-compiler";
const SidebarSectionMessage = template_968930b68b914ccd81e0b12a7089faf0(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
