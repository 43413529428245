import { template as template_6cc7a1a620b1483fa280bda815d28dc2 } from "@ember/template-compiler";
const FKLabel = template_6cc7a1a620b1483fa280bda815d28dc2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
